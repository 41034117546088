import React, { FunctionComponent, memo, useCallback, useEffect, useRef } from "react";
import cx from "classnames";
import { Text } from "@smartsuite/react-ui/lib/components/typography/Text";
import { AppField, ID, SimpleRecord } from "@smartsuite/types";
import { isEnterKey } from "@smartsuite/react-ui/lib/helpers/keys";

import { getDisplayValue } from "./RecordItem.utils";
import { SocialNetworkField } from "./components/SocialNetworkField";
import { BaseRecord } from "../../../../../../../../../types/form";

import "./RecordItem.sass";
import { useFormConfig } from "../../../../../../../../../hooks/useFormConfig";

interface RecordItemProps {
  record: SimpleRecord;
  fieldsToShow: { [key: string]: AppField };
  isSelected: boolean;
  maxVisibleFields: number;
  relatedRecords?: BaseRecord[];
  isFocused: boolean;

  onClick: (recordId: ID) => void;
}

const RecordItemRaw: FunctionComponent<RecordItemProps> = ({
  isSelected,
  fieldsToShow,
  record,
  isFocused = false,
  maxVisibleFields,
  relatedRecords,
  onClick,
}) => {
  const formConfig = useFormConfig();
  const refElement = useRef<HTMLDivElement>(null);
  const classes = cx("record-item", `record-item--select`, {
    "is-selected": isSelected,
    "is-focused": isFocused,
  });

  const handleClick = useCallback(() => {
    onClick(record.id);
  }, [onClick, record.id]);

  const handleEnterKey = useCallback(
    (event: React.KeyboardEvent) => {
      if (isEnterKey(event)) {
        onClick(record.id);
      }
    },
    [onClick, record.id]
  );

  useEffect(() => {
    if (isFocused) {
      refElement.current?.scrollIntoView({ block: "end" });
    }
  }, [isFocused]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      aria-label="Linked Record Item"
      className={classes}
      style={
        window.screen.width <= 1024 ? { width: 170 * Object.keys(fieldsToShow).length } : undefined
      }
      ref={refElement}
      onClick={handleClick}
      onKeyDown={handleEnterKey}
    >
      <Text className="record-item__title" wrapMode="ellipsis" weight="medium" size="l">
        {record.title || "Untitled Record"}
      </Text>

      <div className="record-item__body">
        {Object.keys(fieldsToShow)
          .slice(0, maxVisibleFields)
          .map((fieldSlug) => {
            const value = getDisplayValue(
              record,
              fieldSlug,
              fieldsToShow,
              formConfig?.tags,
              relatedRecords
            );

            const fieldType = fieldsToShow[fieldSlug].field_type;

            if (!value) {
              return (
                <div key={fieldSlug} className="record-item__empty-field">
                  <span className="record-item__line"></span>
                </div>
              );
            }

            return (
              <div className="record-item__field" key={fieldSlug}>
                {fieldType === "socialnetworkfield" && !fieldSlug.includes(".") ? (
                  <SocialNetworkField record={record} fieldSlug={fieldSlug} />
                ) : (
                  <Text size="m" weight="regular" lineClamps={1}>
                    {value.toString()}
                  </Text>
                )}
              </div>
            );
          })}
      </div>

      <>
        <div className="record-item__icons"></div>

        <div className="record-item__corner-wrapper">
          <div className="record-item__corner">
            <div className="record-item__triangle"></div>
          </div>
        </div>
      </>
    </div>
  );
};

export const RecordItem = memo(RecordItemRaw);
