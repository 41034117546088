import { FilterField } from "@smartsuite/types";
import { isArray } from "lodash-es";

interface SelectedFormValues {
  [key: string]: Array<{ id: string }>;
}

export const convertFilterValue = (
  filterField: FilterField,
  selectedFormValues: SelectedFormValues
): FilterField => {
  if (!filterField.value) {
    return filterField;
  }

  if (isArray(filterField.value)) {
    return convertArrayFilterValue(filterField, selectedFormValues);
  } else {
    return convertStringFilterValue(filterField, selectedFormValues);
  }
};

export const convertArrayFilterValue = (
  filterField: FilterField,
  selectedFormValues: SelectedFormValues
): FilterField =>
  filterField?.value?.reduce((acc: string[], oldValue: string) => {
    if (selectedFormValues[oldValue]) {
      return [...acc, ...selectedFormValues[oldValue].map((v) => v.id)];
    }
    return acc;
  }, []);

export const convertStringFilterValue = (
  filterField: FilterField,
  selectedFormValues: SelectedFormValues
): FilterField => {
  const dynamicTextFilterVarRegex = /(\[\[[a-z0-9_]+\]\])/g;

  const tokens = filterField.value.split(dynamicTextFilterVarRegex);

  return tokens.reduce((acc: string[], token: string) => {
    if (dynamicTextFilterVarRegex.test(token)) {
      const value = token.slice(2, -2);
      const selectedValueId = selectedFormValues[value]?.map((v) => v.id);
      if (selectedValueId) {
        return [...acc, ...selectedValueId];
      }
    } else {
      if (token) {
        return [...acc, token];
      }
    }

    return acc;
  }, []);
};
