import { Icon, InputGroup, Pill, Text } from "@smartsuite/react-ui/lib";
import React, { useState } from "react";
import * as yup from "yup";

import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { LinkedRecordObject } from "../../../../types/form";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { validateWithYup } from "../../../validator";
import { validatorText } from "../../../validator.text";
import { linkedRecordPillStyles } from "../linked-record.constants";
import "./LinkedRecordFieldControl.sass";
import { SelectLinkedRecordModal } from "./components/SelectLinkedRecordModal/SelectLinkedRecordModal";
import { GrayColors } from "@smartsuite/types";
import { fieldsText } from "../../fields.text";
import { SolutionColorVariables } from "@smartsuite/types/lib/constants/color";

export type LinkedRecordFieldControlProps = FormFieldProps;

export const LinkedRecordFieldControl: React.FunctionComponent<LinkedRecordFieldControlProps> = ({
  caption,
  field,
  formItem,
  label,
  name,
  required,
}) => {
  const [hovered, setHovered] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMultiple = field.params?.entries_allowed === "multiple";

  const requiredText = $t(validatorText.validationRequired);
  const formField = useFormField({
    name,
    validate: validateWithYup(
      yup
        .array()
        .of(yup.object())
        .when({
          is: () => formItem.required,
          then: (lr) => lr.required(requiredText).min(1, requiredText),
        })
    ),
  });

  const handleSelectChange = (changeValue: LinkedRecordObject | LinkedRecordObject[]): void => {
    if (changeValue) {
      if (Array.isArray(changeValue)) {
        formField.onChange(changeValue);
      } else {
        formField.onChange([changeValue]);
      }
    } else {
      formField.onChange([]);
    }
  };

  const handleInputClick = (): void => {
    setIsModalOpen(true);
  };

  const handleRemove = (id: string): void => {
    formField.onChange(
      formField.value.filter((linkedRecord: LinkedRecordObject) => linkedRecord.id !== id)
    );
  };

  return (
    <>
      <FormFieldControl
        caption={caption}
        errorMessage={formField.errorMessage}
        label={label}
        required={required}
        state={formField.state}
      >
        <InputGroup
          className="linked-record-control__input"
          state={formField.state}
          onClick={handleInputClick}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {!formField.value?.length && (
            <Text className="placeholder" color="inactive">
              {isMultiple
                ? $t(fieldsText.multipleSelectPlaceholder)
                : $t(fieldsText.singleSelectPlaceholder)}
            </Text>
          )}
          {formField.value?.map((linkedRecord: LinkedRecordObject) => (
            <Pill
              key={linkedRecord.id}
              className={"linked-record-standard-select__item"}
              text={linkedRecord.title}
              styles={linkedRecordPillStyles}
              animatedRemove={true}
              onClick={handleInputClick}
              removable
              onRemove={() => handleRemove(linkedRecord.id)}
            />
          ))}
          <Icon
            className="linked-record-control__icon"
            name="arrow-up-1"
            size="M"
            color={hovered ? SolutionColorVariables.sc1 : GrayColors["G-7"]}
          />
        </InputGroup>
      </FormFieldControl>
      {isModalOpen && (
        <SelectLinkedRecordModal
          formField={formField}
          formItem={formItem}
          field={field}
          alreadySelectedRecords={formField.value}
          onSave={handleSelectChange}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};
