import { Preloader } from "@smartsuite/react-ui/lib";
import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { fetchFormConfig } from "../../api";
import { FormNotFoundMessage } from "../../components/FormNotFoundMessage/FormNotFoundMessage";
import { PageContentBox } from "../../components/PageContentBox/PageContentBox";
import { createFilestackClient } from "../../config/filestack";
import { defaultLogoUrl } from "../../helpers/defaultUrls";
import { FormDetail } from "./FormDetail";
import { useIsPreview } from "../../hooks/useIsPreview";

export const FormPageRoute: React.FC = () => {
  const { accountId, hash } = useParams();
  const { isPreview } = useIsPreview();

  if (!accountId || !hash) {
    return null;
  }

  return <FormPage accountId={accountId} hash={hash} isPreview={isPreview} />;
};

interface FormPageProps {
  accountId: string;
  hash: string;
  isPreview?: boolean;
}

export const FormPage: React.FC<FormPageProps> = ({ accountId, hash, isPreview = false }) => {
  const { data, status, isLoading } = useQuery(
    "form",
    async () => await fetchFormConfig(accountId, hash, isPreview)
  );

  const filestack = useMemo(
    () => createFilestackClient(data?.filestack_api_key, { security: data?.file_security }),
    [data?.file_security, data?.filestack_api_key]
  );

  const displayFooter = isLoading ? false : data?.display_smartsuite_branding ?? true;

  const logoUrl = data?.form_state?.logo_handle
    ? filestack.transform(data.form_state.logo_handle, {})
    : defaultLogoUrl;

  useEffect(() => {
    if (status === "success") {
      document.title = data?.form_state?.title
        ? `${data.form_state.title} | SmartSuite`
        : "Form | SmartSuite";
    } else if (status === "error") {
      document.title = "Not Found | SmartSuite";
    }
  }, [data, status]);

  return (
    <PageContentBox logoUrl={isLoading ? null : logoUrl} displayBranding={displayFooter}>
      {status === "loading" && (
        <div style={{ position: "relative", padding: "50px 0" }}>
          <Preloader />
        </div>
      )}

      {status === "error" && <FormNotFoundMessage />}

      {status === "success" && (
        <FormDetail accountId={accountId} hash={hash} config={data} isPreview={isPreview} />
      )}
    </PageContentBox>
  );
};
